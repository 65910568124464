import React, { useState } from "react";
import Button from "../elements/Button";
import Input from "../elements/Input";
import GenericSection from "./GenericSection";

const ContactForm = ({ data, onSubmit }) => {
  const [email, setEmail] = useState(data.email);
  const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState(data.phone);
  const [phoneError, setPhoneError] = useState(false);
  const [name, setName] = useState(data.name);
  const [nameError, setNameError] = useState(false);
  const [message, setMessage] = useState(data.message);
  const [messageError, setMessageError] = useState(false);

  const validate = () => {
    // eslint-disable-next-line
    const phoneRegEx = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
    // eslint-disable-next-line
    const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let error = false;
    if (!name) {
      error = true;
      setNameError(true);
    }
    if (!message) {
      error = true;
      setMessageError(true);
    }
    if (!phoneRegEx.test(phone)) {
      setPhoneError(true);
      error = true;
    }
    if (!emailRegEx.test(email)) {
      setEmailError(true);
      error = true;
    }
    return !error;
  };

  const submit = () => {
    if (validate()) {
      onSubmit({ email, name, phone, message });
    }
  };

  return (
    <GenericSection>
      <div className="container-sm reveal-from-bottom">
        <Input
          data-reveal-delay="100"
          className={emailError ? "mb-16" : "mb-16 reveal-from-bottom"}
          label="Email"
          placeholder="Enter Your Email"
          type="email"
          status={emailError ? "error" : null}
          hint={emailError ? "Must enter a valid Email" : null}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError(false);
          }}
        />
        <Input
          data-reveal-delay="200"
          className={phoneError ? "mb-16" : "mb-16 reveal-from-bottom"}
          label="Phone Number"
          placeholder="Enter Your Phone Number"
          type="tel"
          status={phoneError ? "error" : null}
          hint={phoneError ? "Must enter a valid Phone Number" : null}
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
            setPhoneError(false);
          }}
        />
        <Input
          data-reveal-delay="300"
          className={nameError ? "mb-16" : "mb-16 reveal-from-bottom"}
          label="Name"
          placeholder="Enter Your Name"
          hint={nameError ? "Name cannot be blank" : null}
          type="text"
          status={nameError ? "error" : null}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setNameError(false);
          }}
        />
        <Input
          className={nameError ? "" : "reveal-from-bottom"}
          data-reveal-delay="400"
          label="Message"
          placeholder="Enter Your Message"
          type="textarea"
          rows={7}
          status={messageError ? "error" : null}
          hint={messageError ? "Message cannot be blank" : null}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
            setMessageError(false);
          }}
        />
        <Button
          data-reveal-delay="500"
          className="mt-24 button button-primary button-md reveal-from-bottom"
          wide
          onClick={() => submit()}
        >
          Submit
        </Button>
      </div>
    </GenericSection>
  );
};
export default ContactForm;
