import React, { useState, useEffect } from "react";
import ContactForm from "../components/sections/ContactForm";
import Button from "../components/elements/Button";
import GenericSection from "../components/sections/GenericSection";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    name: "",
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://5qvxeyhlck.execute-api.us-east-1.amazonaws.com/devhampton/contact-us",
        {
          method: "POST",
          body: JSON.stringify({
            ...data,
          }),
        }
      );
      if (!response.ok) {
        throw new Error();
      }
      setSuccess(true);
    } catch (error) {
      setError(true);
      setFormData(data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="illustration-section-01" />
      {loading ? (
        <GenericSection>
          <div
            className="container-sm reveal-from-bottom"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3 className="mt-0 mb-12">Sending Message</h3>
          </div>
        </GenericSection>
      ) : error ? (
        <GenericSection>
          <div className="container-sm">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3 className="ml-24 mt-32 mb-32">
                There was an error sending your request please try again.
              </h3>
              <Button
                className="mt-12 button button-primary button-m"
                wide
                onClick={() => setError(false)}
              >
                Ok
              </Button>
            </div>
          </div>
        </GenericSection>
      ) : success ? (
        <GenericSection>
          <div className="container-sm">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3 className="ml-24 mt-0 mb-12">
                Message Sent! Thank you for reaching out!
              </h3>
              <Link
                to="/"
                className="mt-12 button button-primary button-wide-mobile button-m"
              >
                Home
              </Link>
            </div>
          </div>
        </GenericSection>
      ) : (
        <ContactForm data={formData} onSubmit={(data) => handleSubmit(data)} />
      )}
    </>
  );
};

export default ContactUs;
